import { Carousel as CarouselType } from './Carousel'
import { MenuBanner as MenuBannerType } from './MenuBanner'
import { MiniBasketBanner as MiniBasketBannerType } from './MiniBasketBanner'
import { PromotionBanner as PromotionBannerType } from './PromotionBanner'
import { PushBanners as PushBannersType } from './PushBanners'
import { LoyaltyBanner as LoyaltyBannerType } from './LoyaltyBanner'
import { RecoAI as RecoAIType } from './RecoAI'

export async function getCarousel(): Promise<CarouselType> {
  const { Carousel } = await import(
    /* webpackChunkName: "Carousel" */ './Carousel'
  )
  return (Carousel as unknown) as CarouselType
}

export async function getMenuBanner(): Promise<MenuBannerType> {
  const { MenuBanner } = await import(
    /* webpackChunkName: "MenuBanner" */ './MenuBanner'
  )
  return (MenuBanner as unknown) as MenuBannerType
}

export async function getPromotionBanner(): Promise<PromotionBannerType> {
  const { PromotionBanner } = await import(
    /* webpackChunkName: "PromotionBanner" */ './PromotionBanner'
  )
  return (PromotionBanner as unknown) as PromotionBannerType
}

export async function getPushBanners(): Promise<PushBannersType> {
  const { PushBanners } = await import(
    /* webpackChunkName: "PushBanners" */ './PushBanners'
  )
  return (PushBanners as unknown) as PushBannersType
}

export async function getMiniBasketBanner(): Promise<MiniBasketBannerType> {
  const { MiniBasketBanner } = await import(
    /* webpackChunkName: "MiniBasketBanner" */ './MiniBasketBanner'
  )
  return (MiniBasketBanner as unknown) as MiniBasketBannerType
}

export async function getLoyaltyBanner(): Promise<LoyaltyBannerType> {
  const { LoyaltyBanner } = await import(
    /* webpackChunkName: "LoyaltyBanner" */ './LoyaltyBanner'
  )
  return (LoyaltyBanner as unknown) as LoyaltyBannerType
}

export async function getRecoAI(): Promise<RecoAIType> {
  const { RecoAI } = await import(/* webpackChunkName: "RecoAI" */ './RecoAI')
  return (RecoAI as unknown) as RecoAIType
}

export { observeDocument } from 'multi-observer'
